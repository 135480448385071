<!--
 * @Author: zhangjiaheng
 * @Date: 2020-07-24 09:25:02
 * @LastEditTime: 2022-05-19 14:20:08
 * @LastEditors: zhangjiaheng zhangjiaheng@enfry.com
 * @Description: 全屏组件 中间部分暴露插槽
 * @FilePath: \user\src\components\full-screen\index.vue
-->
<template>
  <div class="full-screen" :style="{ background: background }">
    <en-image-setting name="tuichuquanping" size="small" fill="#F76B6B" @click.native="closeFull">
      关闭全屏
    </en-image-setting>
    <slot name="content"></slot>
  </div>
</template>

<script>
import toggleFullScreen from "@/mixins/toggleFullScreen"; // 公共方法

export default {
  name: "FullScreen",
  props: {
    background: {
      type: String,
      default: "#1D2532"
    }
  },
  mixins: [toggleFullScreen],
  mounted() {
    this.addFullScreenListener(this.finish, this.closeFull);
  },
  methods: {
    /**
     * @description: 全屏开启完成事件
     */
    finish() {
      this.$emit("finish");
    },
    // 关闭事件 需要父级处理 这里只会触发
    closeFull() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.full-screen {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999999;
  width: 100%;
  height: 100%;
  background: #1d2532;
  .en-image-setting {
    position: fixed;
    z-index: 99999999;
    top: 23px;
    right: 23px;
    /deep/.text {
      color: #ffffff !important;
      font-size: 14px;
    }
  }
}
</style>
