var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "full-screen", style: { background: _vm.background } },
    [
      _c(
        "en-image-setting",
        {
          attrs: { name: "tuichuquanping", size: "small", fill: "#F76B6B" },
          nativeOn: {
            click: function ($event) {
              return _vm.closeFull.apply(null, arguments)
            },
          },
        },
        [_vm._v(" 关闭全屏 ")]
      ),
      _vm._t("content"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }