/* eslint-disable */
/*
 * @Description: 切换全屏和非全屏 mixin
 * @Author: 梁平贤
 * @Date: 2020/7/8 11:42
 */

const toggleFullScreen = {
  data() {
    return {
      browserKernel: "",
      canFullScreen: false,
      isFullScreen: false,
      jieliu: false
    };
  },
  methods: {
    // 全屏设置 可以传入需要全屏的节点,默认取当前组件
    toggleFullScreen(el = this.$el) {
      this.jieliu = true;
      if (this.canFullScreen) {
        if (this.isFullScreen) {
          // 关闭全屏
          this.exitFullScreen();
          this.isFullScreen = false;
        } else {
          // 打开全屏
          this.requestFullScreen(document.body);
          this.isFullScreen = true;
        }
      } else {
        this.$message.warning("当前浏览器暂不支持全屏模式，请切换浏览器后重新尝试！");
      }
    },
    requestFullScreen() {
      // 判断各种浏览器，找到正确的方法
      // const requestMethod =
      //   element.requestFullScreen || // W3C
      //   element.webkitRequestFullScreen || // Chrome, safari
      //   element.mozRequestFullScreen || // FireFox
      //   element.msRequestFullscreen; // IE11
      // if (requestMethod) {
      //   requestMethod.call(element);
      //   // requestMethod();
      // }
      if (document.body.webkitRequestFullScreen) {
        document.body.webkitRequestFullScreen();
      } else if (document.body.mozRequestFullScreen) {
        document.body.mozRequestFullScreen();
      } else if (document.body.requestFullScreen) {
        document.body.requestFullscreen();
      } else if (document.body.msRequestFullscreen) {
        document.body.msRequestFullscreen(); // IE11
      } else {
        // 浏览器不支持全屏API或已被禁用
      }
    },
    exitFullScreen() {
      const exitMethod =
        document.exitFullscreen || // W3C
        document.mozCancelFullScreen || // FireFox
        document.webkitExitFullscreen || // Chrome等
        document.msExitFullscreen; // IE11
      if (exitMethod) {
        exitMethod.call(document);
      }
    },
    // 全屏监听事件 finish全屏完成回调 close全屏关闭回调
    addFullScreenListener(finish, close) {
      const self = this;
      document.onkeydown = function(e) {
        if (e && e.keyCode === 122) {
          // 捕捉F11键盘动作
          e.preventDefault(); // 阻止F11默认动作
          if (!self.jieliu) {
            self.toggleFullScreen();
            self.jieliu = false;
          }
        }
      };
      // 监听不同浏览器的全屏事件，并件执行相应的代码
      switch (self.browserKernel) {
        case "webkit":
          document.onwebkitfullscreenchange = function() {
            console.log(1111111111);
            if (document.webkitIsFullScreen) {
              self.isFullScreen = true;
              finish && finish();
            } else {
              self.isFullScreen = false;
              close && close();
            }
          };
          break;
        case "gecko":
          document.onmozfullscreenchange = function() {
            if (document.mozFullScreen) {
              self.isFullScreen = true;
              finish && finish();
            } else {
              self.isFullScreen = false;
              close && close();
            }
          };
          break;
        case "trident":
          document.onmsfullscreenchange = function() {
            if (document.msFullscreenElement) {
              self.isFullScreen = true;
              finish && finish();
            } else {
              self.isFullScreen = false;
              close && close();
            }
          };
          break;
        case "others":
          document.onfullscreenchange = function() {
            if (document.fullscreen) {
              self.isFullScreen = true;
              finish && finish();
            } else {
              self.isFullScreen = false;
              close && close();
            }
          };
          break;
        default:
          break;
      }
    },
    // 判断当前页面是否是全屏
    isFullScreenFun () {
      switch (this.browserKernel) {
        case "webkit":
          if (document.webkitIsFullScreen) {
            this.isFullScreen = true;
          } else {
            this.isFullScreen = false;
          }
          break;
        case "gecko":
          if (document.mozFullScreen) {
            this.isFullScreen = true;
          } else {
            this.isFullScreen = false;
          }
          break;
        case "trident":
          if (document.msFullscreenElement) {
            this.isFullScreen = true;
          } else {
            this.isFullScreen = false;
          }
          break;
        case "others":
          if (document.fullscreen) {
            this.isFullScreen = true;
          } else {
            this.isFullScreen = false;
          }
          break;
        default:
          break;
      }
    }
  },
  created() {
    this.canFullScreen = document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled;
    if (document.webkitFullscreenEnabled) {
      this.browserKernel = "webkit";
    } else if (document.mozFullScreenEnabled) {
      this.browserKernel = "gecko";
    } else if (document.msFullscreenEnabled) {
      this.browserKernel = "trident";
    } else if (document.fullscreenEnabled) {
      this.browserKernel = "others";
    }
    if (this.canFullScreen) {
      this.addFullScreenListener();
    }
  },
  beforeDestroy() {
    document.onkeydown = null;
    switch (this.browserKernel) {
      case "webkit":
        document.onwebkitfullscreenchange = null;
        break;
      case "gecko":
        document.onmozfullscreenchange = null;
        break;
      case "trident":
        document.onmsfullscreenchange = null;
        break;
      case "others":
        document.onfullscreenchange = null;
        break;
      default:
        break;
    }
  }
};

export default toggleFullScreen;
